import { GqlQuery } from '@/graphql/operations/GqlQuery';
import { gql } from '../codegen';

export const document = gql(`
  query ListSystemNodesBySystemNodeIdsForDownload($systemNodeIds: [ID]!) {
    findSystemNodesByIds(systemNodeIds: $systemNodeIds) {
      ... on FileNode {
        __typename
        id
        kind
        name
        parentNodeId
        fileId
      }
      ... on FolderNode {
        __typename
        id
        kind
        name
        parentNodeId
      }
      ...on LinkNode {
        __typename
        id
        kind
        name
        parentNodeId
        sourceNodeId
      }
    }
  }
`);

export const ListSystemNodesBySystemNodeIdsForDownload = GqlQuery.fromGql(document);
