import { NODE_KIND, NodeKind } from '@/constants/node';
import { UserV2 } from '@/graphql/codegen/graphql';
import { useSystemNodesBySystemNodeIdsForPreview } from '@/hooks/useSystemNodesBySystemNodeIdsForPreview';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';
import { cn } from '@/utils/classname';
import { formatDecimalBytes } from '@/utils/formatBytes';
import { displayName } from '@/utils/user';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Input } from '@skand/ui';
import { useCallback, useEffect, useState } from 'react';
import { FoldersPageTableData } from './useFoldersPageColumns';

export interface DetailsPopupButtonProps {
  nodeDetails: FoldersPageTableData;
  onUpdateDetails: (
    kind: NodeKind,
    extension: string,
    updates: { systemNodeId: string; name: string },
  ) => void;
}

const DetailsField = ({ title, value }: { title: string; value: string }) => (
  <div className="flex justify-between">
    <h3 className="color-neutral-800 typo-text-s">{title}</h3>
    <p className="color-neutral-600 typo-text-s">{value}</p>
  </div>
);

export const DetailsPopupButton = ({ nodeDetails, onUpdateDetails }: DetailsPopupButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const { userHasEditPermission } = useSystemNodesUserActionsPermissionsBySystemNodeIds({
    systemNodeIds: [nodeDetails.id],
  });

  const { findSystemNodeById } = useSystemNodesBySystemNodeIdsForPreview();
  const systemNode = findSystemNodeById(nodeDetails.id);
  const systemNodeFileSize = systemNode?.file?.storage?.size ?? null;
  const { amount, unit } = formatDecimalBytes(systemNodeFileSize);

  useEffect(() => {
    if (isOpen && nodeDetails) {
      setName(nodeDetails.name);
    }
  }, [isOpen, nodeDetails]);

  const getTitle = () =>
    nodeDetails?.kind === NODE_KIND.FOLDER_NODE ? 'Folder details' : 'File details';

  const handleUpdateDetails = useCallback(() => {
    onUpdateDetails(nodeDetails?.kind as NodeKind, nodeDetails?.extension, {
      systemNodeId: nodeDetails?.id,
      name,
    });
  }, [name, nodeDetails?.extension, nodeDetails?.id, nodeDetails?.kind, onUpdateDetails]);

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger asChild>
        <Button active={isOpen} className="whitespace-nowrap hover:cursor-pointer" size="s">
          View details
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className={cn(
            'b-1 b-neutral-400 b-solid rounded-lg shadow-md',
            'w-300px p-2',
            'bg-neutral-100',
            'flex flex-col gap-2',
          )}
          side="bottom"
          sideOffset={5}
        >
          <div className="flex items-center justify-between">
            <h3 className="color-neutral-800 typo-text-m">{getTitle()}</h3>
            <div
              className={cn('i-skand-close text-3 color-neutral-400', 'hover:cursor-pointer')}
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className={cn('color-neutral-800 typo-text-s', 'flex flex-col gap-2')}>
            <Input disabled={!userHasEditPermission} label="Name" value={name} onChange={setName} />

            <DetailsField
              title="Created date"
              value={new Date(nodeDetails?.createdAt as string).toLocaleString()}
            />
            <DetailsField
              title="Created by"
              value={displayName(nodeDetails?.createdUser as UserV2)}
            />
            {systemNodeFileSize && <DetailsField title="Size" value={`${amount} ${unit}`} />}
            <DetailsField
              title="Last downloaded at"
              value={
                nodeDetails?.lastDownloadedAt
                  ? new Date(nodeDetails?.lastDownloadedAt as string).toLocaleString()
                  : 'N/A'
              }
            />
            <DetailsField
              title="Last downloaded by"
              value={
                nodeDetails?.lastDownloadedUser
                  ? displayName(nodeDetails?.lastDownloadedUser as UserV2)
                  : 'N/A'
              }
            />
          </div>
          <div className="flex">
            <Button
              filled
              primary
              className={cn('w-full', { 'hover:cursor-pointer': userHasEditPermission })}
              disabled={!userHasEditPermission || name === nodeDetails?.name}
              size="s"
              onClick={() => handleUpdateDetails()}
            >
              Update
            </Button>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
