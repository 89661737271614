import { ListSystemNodesBySystemNodeIdsForPreview } from '@/graphql/queries/ListSystemNodesBySystemNodeIdsForPreview.gql';
import { useFetchAndManageSystemNodes } from './useFetchAndManageSystemNodes';

export const useSystemNodesBySystemNodeIdsForPreview = () => {
  const { displaySystemNodes } = useFetchAndManageSystemNodes();
  const systemNodeIds = displaySystemNodes
    .reduce<string[]>((prev, curr) => {
      if (curr.fileId) return [...prev, curr.id];
      return prev;
    }, [])
    .sort();

  const response = ListSystemNodesBySystemNodeIdsForPreview.useQuery(
    { systemNodeIds },
    { enabled: systemNodeIds.length > 0 },
  );

  const systemNodes = response.data?.findSystemNodesByIds ?? [];

  const findSystemNodeById = (id: string) => {
    return systemNodes.find(node => node && node.__typename === 'FileNode' && node.id === id) as
      | undefined
      | {
          __typename: 'FileNode';
          id?: string | null;
          kind?: string | null;
          name?: string | null;
          parentNodeId?: string | null;
          fileId?: string | null;
          file?:
            | {
                __typename?: 'AssetFile';
                signedGetObjectUrl?: string | null;
                signedGetObjectDownloadUrl?: string | null;
                storage?: {
                  __typename?: 'FileStorageField';
                  name?: string | null;
                  size?: number | null;
                } | null;
              }
            | {
                __typename?: 'ImageFile';
                signedGetObjectUrl?: string | null;
                signedGetObjectDownloadUrl?: string | null;
                storage?: {
                  __typename?: 'FileStorageField';
                  name?: string | null;
                  size?: number | null;
                } | null;
              }
            | {
                __typename?: 'ObjFile';
                signedGetObjectUrl?: string | null;
                signedGetObjectDownloadUrl?: string | null;
                storage?: {
                  __typename?: 'FileStorageField';
                  name?: string | null;
                  size?: number | null;
                } | null;
              }
            | {
                __typename?: 'Ortho2dFile';
                signedGetObjectUrl?: string | null;
                signedGetObjectDownloadUrl?: string | null;
                storage?: {
                  __typename?: 'FileStorageField';
                  name?: string | null;
                  size?: number | null;
                } | null;
              }
            | {
                __typename?: 'Tileset3dFile';
                signedGetObjectUrl?: string | null;
                signedGetObjectDownloadUrl?: string | null;
                storage?: {
                  __typename?: 'FileStorageField';
                  name?: string | null;
                  size?: number | null;
                } | null;
              }
            | null;
        };
  };

  return { findSystemNodeById, response, systemNodes };
};
