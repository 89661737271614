import { NODE_KIND } from '@/constants/node';
import { ACTION_TYPE } from '@/constants/policy';
import { useCreateSystemNodeShareLink } from '@/hooks/useCreateSystemNodeShareLinkMutation';
import { useDeleteSystemNodeMutation } from '@/hooks/useDeleteSystemNodeMutation';
import { useSystemNodesBySystemNodeIdsForPreview } from '@/hooks/useSystemNodesBySystemNodeIdsForPreview';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';
import {
  addToDeletedSystemNodeIds,
  setEditingSystemNodeId,
  setIsOpenMoveToDialog,
} from '@/stores/dataManagementStore';
import { cn } from '@/utils/classname';
import { copyUrl } from '@/utils/copy';
import { getShareLinkToken } from '@/utils/shareLink';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Menu, MenuItem, toast } from '@skand/ui';
import { Row } from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import { DownloadMenuItem } from './DownloadMenuItem';
import { FoldersPageTableData } from './useFoldersPageColumns';

export interface RowActionsProps {
  row: Row<FoldersPageTableData>;
  selectedRows: FoldersPageTableData[];
}

export const RowActions = ({ row, selectedRows }: RowActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const deleteSystemNode = useDeleteSystemNodeMutation();
  const createSystemNodeShareLink = useCreateSystemNodeShareLink();

  const { id: nodeId, fileId } = row.original;
  const isFileKind = row.original.kind === NODE_KIND.FILE_NODE;

  const { userHasMovePermission, userHasDeletePermission } =
    useSystemNodesUserActionsPermissionsBySystemNodeIds({
      systemNodeIds: [nodeId],
    });

  const { findSystemNodeById } = useSystemNodesBySystemNodeIdsForPreview();
  const systemNode = findSystemNodeById(nodeId);
  const shareLink = systemNode?.file?.signedGetObjectDownloadUrl;

  const handleCopyShareLink = useCallback(async () => {
    if (!isFileKind) {
      const result = await createSystemNodeShareLink.mutateAsync({
        nodeId: nodeId,
        name: row.original.name,
        actionType: ACTION_TYPE.READ,
      });

      const shareToken = result?.createNodeShareLink?.shareToken;
      const shareLinkUrl = `${window.location.origin}/datasets/folders?shareLinkToken=${shareToken}`;
      if (shareToken) {
        await copyUrl(shareLinkUrl, row.original.name);
        toast({
          clickToDismiss: true,
          lifespan: 3000,
          message: 'Link copied!',
          type: 'success',
        });
      }
      return;
    }

    if (!shareLink) return;
    await copyUrl(shareLink, row.original.name);
    toast({
      clickToDismiss: true,
      lifespan: 3000,
      message: 'Link copied!',
      type: 'success',
    });
  }, [row.original.name, shareLink, createSystemNodeShareLink, isFileKind, nodeId]);

  const handleOpenMoveToDialog = useCallback(() => {
    setEditingSystemNodeId(nodeId);
    setIsOpenMoveToDialog(true);
  }, [nodeId]);

  const handleDeleteSystemNode = useCallback(async () => {
    await deleteSystemNode.mutateAsync(nodeId);
    addToDeletedSystemNodeIds(nodeId);
  }, [deleteSystemNode, nodeId]);

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger asChild>
        <div
          className={cn(
            'hover:cursor-pointer',
            'i-skand-more hover:color-primary-400',
            isOpen ? 'color-primary-400' : 'color-neutral-400',
          )}
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content asChild align="end" side="bottom">
          <Menu>
            <DownloadMenuItem
              disabled={!isFileKind && !!getShareLinkToken()}
              fileId={fileId}
              nodeId={nodeId}
              selectedRows={selectedRows}
            />
            <DropdownMenu.Item
              asChild
              className={cn('outline-none', { 'cursor-pointer': userHasMovePermission })}
            >
              <MenuItem disabled={!userHasMovePermission} onClick={handleOpenMoveToDialog}>
                Move to
              </MenuItem>
            </DropdownMenu.Item>

            <DropdownMenu.Item asChild className="cursor-pointer outline-none">
              <MenuItem
                disabled={!isFileKind && !!getShareLinkToken()}
                onClick={handleCopyShareLink}
              >
                Copy share link
              </MenuItem>
            </DropdownMenu.Item>

            <DropdownMenu.Item
              asChild
              className={cn('outline-none', { 'cursor-pointer': userHasDeletePermission })}
            >
              <MenuItem disabled={!userHasDeletePermission} onClick={handleDeleteSystemNode}>
                Delete
              </MenuItem>
            </DropdownMenu.Item>
          </Menu>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
