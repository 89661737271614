import { request } from '@/graphql/request';
import logger from '@/utils/logger';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { getOperationName } from './utils';

export interface IGqlOperation<
  Vars extends Record<string, unknown>,
  Data extends Record<string, unknown>,
> {
  readonly document: TypedDocumentNode<Data, Vars>;
  readonly operationName: string;
  request: (variables: Vars, options?: { signal?: AbortSignal }) => Promise<Data>;
}

type RequestFn = <Vars, Data>(options: {
  document: TypedDocumentNode<Data, Vars>;
  variables: Vars;
  signal?: AbortSignal;
}) => Promise<Data>;

export class GqlOperation<
  Vars extends Record<string, unknown>,
  Data extends Record<string, unknown>,
> implements IGqlOperation<Vars, Data>
{
  private _requestFn: RequestFn;
  readonly document: TypedDocumentNode<Data, Vars>;
  readonly operationName: string;

  constructor(
    document: TypedDocumentNode<Data, Vars>,
    operationName: string,
    requestFn: RequestFn,
  ) {
    this._requestFn = requestFn;
    this.document = document;
    this.operationName = operationName;
  }

  static fromGql<V extends Record<string, unknown>, D extends Record<string, unknown>>(
    document: TypedDocumentNode<D, V>,
  ) {
    const operationName = getOperationName(document);
    return new GqlOperation(document, operationName, request);
  }

  async request(
    variables: Vars,
    options?: {
      signal?: AbortSignal;
    },
  ) {
    return this._request(variables, options?.signal);
  }

  private async _request(variables: Vars, signal?: AbortSignal) {
    logger.debug(this.operationName, variables);

    const response = await this._requestFn({
      document: this.document,
      variables,
      signal,
    });

    logger.debug(this.operationName, 'succeeded');
    return response;
  }
}
