import { Downloader } from './Downloader/Downloader';
import { IDownloader } from './Downloader/IDownloader';

export type DownloadTreeNode = FileTreeNode | FolderTreeNode;

export class FileTreeNode {
  readonly downloader: IDownloader;
  readonly fileDownloadUrl: string;
  readonly fileId: string;
  readonly fileSize: undefined | number;
  readonly nodeName: string;
  readonly relativePath: string;
  readonly systemNodeId: string;

  constructor(args: {
    fileDownloadUrl: string;
    fileId: string;
    fileSize?: number;
    nodeName: string;
    relativePath: string;
    systemNodeId: string;
  }) {
    this.downloader = new Downloader(args.fileDownloadUrl, args.fileSize);
    this.fileDownloadUrl = args.fileDownloadUrl;
    this.fileId = args.fileId;
    this.fileSize = args.fileSize;
    this.nodeName = args.nodeName;
    this.relativePath = args.relativePath;
    this.systemNodeId = args.systemNodeId;
  }
}

export class BranchTreeNode {
  private _childNodes: Record<string, DownloadTreeNode> = {};

  addChildNode(node: DownloadTreeNode) {
    this._childNodes[node.systemNodeId] = node;
  }

  forEach(callback: (node: DownloadTreeNode) => void) {
    for (const node of Object.values(this._childNodes)) {
      callback(node);

      if (node instanceof FolderTreeNode) {
        node.forEach(callback);
      }
    }
  }

  forEachFileNode(callback: (node: FileTreeNode) => void) {
    for (const node of Object.values(this._childNodes)) {
      if (node instanceof FileTreeNode) {
        callback(node);
      } else if (node instanceof FolderTreeNode) {
        node.forEachFileNode(callback);
      }
    }
  }

  forEachFolderNode(callback: (node: FolderTreeNode) => void) {
    for (const node of Object.values(this._childNodes)) {
      if (node instanceof FolderTreeNode) {
        callback(node);
        node.forEachFolderNode(callback);
      }
    }
  }

  forEachChildNode(callback: (node: DownloadTreeNode) => void) {
    for (const node of Object.values(this._childNodes)) {
      callback(node);
    }
  }

  map<T>(callback: (node: DownloadTreeNode) => T): T[] {
    const result: T[] = [];
    this.forEach(node => {
      result.push(callback(node));
    });
    return result;
  }

  mapFileNodes<T>(callback: (node: FileTreeNode) => T): T[] {
    const result: T[] = [];
    this.forEachFileNode(node => {
      result.push(callback(node));
    });
    return result;
  }

  mapFolderNodes<T>(callback: (node: FolderTreeNode) => T): T[] {
    const result: T[] = [];
    this.forEachFolderNode(node => {
      result.push(callback(node));
    });
    return result;
  }

  mapChildNodes<T>(callback: (node: DownloadTreeNode) => T): T[] {
    const result: T[] = [];
    this.forEachChildNode(node => {
      result.push(callback(node));
    });
    return result;
  }
}

export class FolderTreeNode extends BranchTreeNode {
  readonly nodeName: string;
  readonly systemNodeId: string;
  readonly relativePath: string;

  constructor(args: { nodeName: string; systemNodeId: string; relativePath: string }) {
    super();
    this.nodeName = args.nodeName;
    this.systemNodeId = args.systemNodeId;
    this.relativePath = args.relativePath;
  }
}
