import { downloadStore } from '@/stores/downloadStore';
import { cn } from '@/utils/classname';
import { formatBinaryBytes } from '@/utils/formatBytes';
import { DownloadTaskManager } from '@skand/downloader';
import { Progress } from '@skand/ui';
import { atom, useAtomValue } from 'jotai';
import { ReactNode } from 'react';

export interface DownloadTaskManagerRowProps {
  taskManager: DownloadTaskManager;
}

const DEFAULT_NUMBER_ATOM = atom(0);
const DEFAULT_ITEMS_TOTAL_ATOM = atom(1);

export const DownloadTaskManagerRow = ({ taskManager }: DownloadTaskManagerRowProps) => {
  const downloadTask = useAtomValue(taskManager.downloadTaskAtom);
  const isPreparing = downloadTask === null;
  const name = downloadTask?.name ?? 'Preparing...';
  const averageSpeed = useAtomValue(downloadTask?.progress.averageSpeedAtom ?? DEFAULT_NUMBER_ATOM);
  const bytesTotal = useAtomValue(downloadTask?.progress.bytesTotalAtom ?? DEFAULT_NUMBER_ATOM);
  const proportion = useAtomValue(downloadTask?.progress.proportionAtom ?? DEFAULT_NUMBER_ATOM);
  const error = useAtomValue(taskManager.errorAtom);

  const percent = proportion * 100;
  const sizeMeta = formatBinaryBytes(bytesTotal, 1);
  const size = `${sizeMeta.amount} ${sizeMeta.unit}`;
  const speedMeta = formatBinaryBytes(averageSpeed, 1);
  const speed = `${speedMeta.amount} ${speedMeta.unit}/s`;

  // itemsTotal === 0 indicates that the download contains empty folders only.
  const itemsTotal = useAtomValue(
    downloadTask?.progress.itemsTotalAtom ?? DEFAULT_ITEMS_TOTAL_ATOM,
  );
  const onlyContainsEmptyFolders = itemsTotal === 0;

  const progress = isPreparing || onlyContainsEmptyFolders ? 1 : proportion;

  const cancelTask = () => {
    downloadStore.removeTaskManager(taskManager);
    taskManager.abort();
  };

  let secondLine: null | ReactNode = null;
  if (error) {
    secondLine = (
      <div className="col-span-10 color-alert-400 typo-text-s">
        1 or more items have failed to download. You can{' '}
        <a
          className="cursor-pointer decoration-underline typo-text-s-em"
          onClick={taskManager.retry}
        >
          retry
        </a>{' '}
        or{' '}
        <a className="cursor-pointer decoration-underline typo-text-s-em" onClick={cancelTask}>
          cancel
        </a>{' '}
        this download.
      </div>
    );
  } else if (isPreparing) {
    secondLine = (
      <div className="col-span-10 color-neutral-500 typo-text-s">
        We are collecting download information. The download will start soon.
      </div>
    );
  } else if (onlyContainsEmptyFolders) {
    secondLine = (
      <div className="col-span-10 color-neutral-500 typo-text-s">
        The download contains empty folders only.
      </div>
    );
  }

  return (
    <>
      <div className="grid col-span-10 grid-cols-subgrid items-center gap-x-2">
        <span
          className={cn(
            'col-span-3 truncate typo-text-s',
            error ? 'color-alert-400' : 'color-neutral-800 ',
          )}
          title={name}
        >
          {name}
        </span>

        <div className="col-span-2 w-full truncate text-right color-neutral-500 typo-text-s">
          {size}
        </div>

        <Progress animated={isPreparing} className="col-span-1" progress={progress} />

        <div className="col-span-1 w-full truncate color-neutral-500 typo-text-s">
          {Math.trunc(percent)}%
        </div>

        <div className="col-span-2 w-full truncate color-neutral-500 typo-text-s">{speed}</div>

        <div className="col-span-1 w-full flex items-center justify-end justify-self-end gap-2">
          {!!error && (
            <div
              className="i-skand-undo cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
              onClick={taskManager.retry}
            />
          )}

          <div
            className="i-skand-closesmall cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
            onClick={cancelTask}
          />
        </div>

        {secondLine}
      </div>
    </>
  );
};
