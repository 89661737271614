import { SizeExcessError } from '@/useCases/BuildSystemNodeTreeForDownloadUseCase';
import { formatBinaryBytes } from '@/utils/formatBytes';
import { toast } from '@skand/ui';
import { BranchTreeNode } from './DownloadTreeNode';

export class EmptyDownloadTreeNodeError extends Error {
  readonly downloadTreeNode: BranchTreeNode;
  constructor(downloadTreeNode: BranchTreeNode) {
    super();
    this.downloadTreeNode = downloadTreeNode;
  }
}

export class InvalidContentLengthError extends Error {
  readonly contentLength: unknown;
  constructor(contentLength: unknown) {
    super();
    this.contentLength = contentLength;
  }
}

export const DOWNLOAD_TASK_HAS_BEEN_ABORTED = Symbol('download task has been aborted');
export const isAbortionError = (error: unknown) => {
  if (error === DOWNLOAD_TASK_HAS_BEEN_ABORTED) {
    return true;
  }

  if (error instanceof DOMException && error.name === 'AbortError') {
    return true;
  }

  return false;
};

export const toastError = () => {
  toast({
    type: 'warn',
    message: (
      <span>
        Something went wrong while downloading, please try again later or contact us at{' '}
        <a className="color-primary-400 typo-link-s" href="mailto:support@skand.io">
          support@skand.io
        </a>
      </span>
    ),
  });
};

export const toastEmptyDownloadTreeNodeError = () => {
  toast({
    type: 'info',
    message: 'There is nothing to download in your selection.',
  });
};

export const toastSizeExcessError = (error: SizeExcessError) => {
  const size = formatBinaryBytes(error.size);
  const maxSize = formatBinaryBytes(error.maxSize);

  toast({
    type: 'warn',
    message: `The total size (${size.amount} ${size.unit}) of your selection to download exceeds ${maxSize.amount} ${maxSize.unit}. Please select less files.`,
  });
};
