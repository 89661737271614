import { jotaiStore } from '@/stores/jotaiStore';
import { atom, PrimitiveAtom } from 'jotai';
import { calcAverageSpeed } from './calcAverageSpeed';
import { IProgress } from './IProgress';

export class GroupProgress implements IProgress {
  private _progressesAtom: PrimitiveAtom<IProgress[]>;
  private _store = jotaiStore;

  constructor(progresses: IProgress[]) {
    this._progressesAtom = atom(progresses);
  }

  readonly bytesLoadedAtom = atom(get => {
    const progresses = get(this._progressesAtom);
    const atoms = progresses.map(progress => get(progress.bytesLoadedAtom));
    return atoms.reduce((prev, curr) => prev + curr, 0);
  });

  readonly bytesTotalAtom = atom(get => {
    const progresses = get(this._progressesAtom);
    const atoms = progresses.map(progress => get(progress.bytesTotalAtom));
    return atoms.reduce((prev, curr) => prev + curr, 0);
  });

  readonly loadFinishedAtAtom = atom(get => {
    let lastFinishedAt: null | Date = null;
    const progresses = get(this._progressesAtom);
    const loadFinishedAts = progresses.map(progress => get(progress.loadFinishedAtAtom));

    for (const loadFinishedAt of loadFinishedAts) {
      if (loadFinishedAt === null) return null;
      if (lastFinishedAt === null) lastFinishedAt = loadFinishedAt;
      else if (loadFinishedAt > lastFinishedAt) lastFinishedAt = loadFinishedAt;
    }

    return lastFinishedAt;
  });
  readonly loadStartedAtAtom = atom(get => {
    let firstStartedAt: null | Date = null;
    const progresses = get(this._progressesAtom);
    for (const loadStartedAt of progresses.map(progress => get(progress.loadStartedAtAtom))) {
      if (loadStartedAt !== null) {
        if (firstStartedAt === null || loadStartedAt < firstStartedAt) {
          firstStartedAt = loadStartedAt;
        }
      }
    }
    return firstStartedAt;
  });

  readonly averageSpeedAtom = atom(get => {
    return calcAverageSpeed(
      get(this.bytesLoadedAtom),
      get(this.loadStartedAtAtom),
      get(this.loadFinishedAtAtom),
    );
  });
  readonly hasFinishedAtom = atom(get => get(this.loadFinishedAtAtom) !== null);
  readonly hasStartedAtom = atom(get => get(this.loadStartedAtAtom) !== null);
  readonly proportionAtom = atom(get => {
    return get(this.bytesTotalAtom) === 0
      ? 0
      : get(this.bytesLoadedAtom) / get(this.bytesTotalAtom);
  });
  readonly itemsLoadedAtom = atom(get => {
    const progresses = get(this._progressesAtom);
    const atoms = progresses.map(progress => get(progress.hasFinishedAtom));
    return atoms.reduce((prev, curr) => (curr ? prev + 1 : prev), 0);
  });
  readonly itemsTotalAtom = atom(get => {
    const progresses = get(this._progressesAtom);
    return progresses.length;
  });

  addProgresses(...progresses: IProgress[]) {
    this._store.set(this._progressesAtom, prev => [...prev, ...progresses]);
  }

  replaceProgresses(...progresses: IProgress[]) {
    this._store.set(this._progressesAtom, progresses);
  }
}
