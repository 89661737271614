import { downloadStore } from '@/stores/downloadStore/downloadStore';
import { jotaiStore } from '@/stores/jotaiStore';
import {
  BuildSystemNodeTreeForDownloadUseCase,
  SizeExcessError,
} from '@/useCases/BuildSystemNodeTreeForDownloadUseCase';
import logger from '@/utils/logger';
import { UNITS } from '@skand/uploader';
import { atom } from 'jotai';
import { DownloadTaskFactory } from './DownloadTask/DownloadTaskFactory';
import { IDownloadTask } from './DownloadTask/IDownloadTask';
import {
  DOWNLOAD_TASK_HAS_BEEN_ABORTED,
  EmptyDownloadTreeNodeError,
  isAbortionError,
  toastEmptyDownloadTreeNodeError,
  toastError,
  toastSizeExcessError,
} from './errors';

export type DownloadTaskStage =
  | 'initial'
  | 'preparing'
  | 'downloading'
  | 'failure'
  | 'success'
  | 'aborted';

/**
 * DownloadTaskManager manages lifecycle of a download task.
 */
export class DownloadTaskManager {
  private _store = jotaiStore;
  private _abortController = new AbortController();
  readonly id: string;
  readonly maxTotalSize: number;
  readonly systemNodeIds: string[];

  private _downloadTaskAtom = atom<null | IDownloadTask>(null);
  private _errorAtom = atom<null | unknown>(null);
  private _stageAtom = atom<DownloadTaskStage>('initial');

  readonly downloadTaskAtom = atom(get => get(this._downloadTaskAtom));
  readonly errorAtom = atom(get => get(this._errorAtom));
  readonly stageAtom = atom(get => get(this._stageAtom));

  static createId(systemNodeIds: string[]) {
    const sortedSystemNodeIds = [...systemNodeIds].sort();
    return sortedSystemNodeIds.join(',');
  }

  constructor(systemNodeIds: string[], options?: { maxTotalSize?: number }) {
    this.systemNodeIds = systemNodeIds;
    this.id = DownloadTaskManager.createId(systemNodeIds);
    this.maxTotalSize = options?.maxTotalSize ?? 10 * UNITS.BYTE.GB;
  }

  start = async () => {
    try {
      this._store.set(this._stageAtom, 'preparing');
      await this._prepare();
      this._store.set(this._stageAtom, 'downloading');
      await this._download();
      this._store.set(this._stageAtom, 'success');
    } catch (error) {
      logger.warn(error);
      this._store.set(this._errorAtom, error);
      if (isAbortionError(error)) {
        this._store.set(this._stageAtom, 'aborted');
      } else if (error instanceof SizeExcessError) {
        this._store.set(this._stageAtom, 'failure');
        toastSizeExcessError(error);
        // cancel itself
        downloadStore.removeTaskManager(this);
        this.abort();
      } else if (error instanceof EmptyDownloadTreeNodeError) {
        this._store.set(this._stageAtom, 'failure');
        toastEmptyDownloadTreeNodeError();
        // cancel itself
        downloadStore.removeTaskManager(this);
        this.abort();
      } else {
        this._store.set(this._stageAtom, 'failure');
        toastError();
        throw error;
      }
    }
  };

  retry = async () => {
    if (this._abortController.signal.aborted) {
      this._abortController = new AbortController();
    }

    this._store.set(this._errorAtom, null);
    this._store.set(this._stageAtom, 'initial');
    await this.start();
  };

  abort() {
    // Abort reason will always be logged as a warning in console by browser.
    // If we pass an error in, like `this._abortController.abort(new AbortionError())`,
    // The error will be printed repeatedly in console. So we use a magic symbol instead.
    // Also remember to check it in `isAbortionError`.
    this._abortController.abort(DOWNLOAD_TASK_HAS_BEEN_ABORTED);
  }

  private async _prepare() {
    // skip preparation if downloadTask is already prepared
    const currentDownloadTask = this._store.get(this._downloadTaskAtom);
    if (currentDownloadTask) return;

    const useCase = new BuildSystemNodeTreeForDownloadUseCase();
    const rootTreeNode = await useCase.execute(this.systemNodeIds, {
      abortSignal: this._abortController.signal,
      maxTotalSize: this.maxTotalSize,
    });

    const downloadTaskFactory = new DownloadTaskFactory();
    const downloadTask = downloadTaskFactory.createFromTree(rootTreeNode);
    this._store.set(this._downloadTaskAtom, downloadTask);
  }

  private async _download() {
    const downloadTask = this._store.get(this._downloadTaskAtom)!;
    await downloadTask.start(this._abortController.signal);
  }
}
