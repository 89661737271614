import { DEFAULT_ERROR_RETRY_COUNT } from '@/constants/error';
import { MAX_TIME_MS_EXPIRED_ERROR_CODE } from '@/constants/node';
import {
  ListSystemNodesByParentNodeIdQueryVariables,
  SystemNodeKindInput,
} from '@/graphql/codegen/graphql';
import { LIST_SYSTEM_NODES_BY_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isArray, isDefined } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (
  parentNodeId?: null | string,
  pageSize?: number,
  pageIndex?: number,
  searchTerm?: string,
  nodeKinds?: SystemNodeKindInput[],
  includeMigratedData?: boolean,
) => [
  'LIST_SYSTEM_NODES_BY_PARENT_NODE_ID',
  parentNodeId,
  pageSize,
  pageIndex,
  searchTerm,
  nodeKinds,
  includeMigratedData
];

export const useListSystemNodesByParentNodeIdQuery = (
  queryParam: ListSystemNodesByParentNodeIdQueryVariables,
  options?: { keepPreviousData?: boolean; enabled?: boolean },
) => {
  const response = useQuery({
    enabled: options?.enabled ?? true,
    queryKey: getQueryKey(
      queryParam.parentNodeId,
      queryParam.pageSize,
      queryParam.pageIndex,
      queryParam.searchTerm || undefined,
      (queryParam.nodeKinds as SystemNodeKindInput[]) || [
        SystemNodeKindInput.FileNode,
        SystemNodeKindInput.FolderNode,
      ],
    ),
    queryFn: () =>
      request(LIST_SYSTEM_NODES_BY_PARENT_NODE_ID, {
        ...queryParam,
        includeMigratedData: queryParam.includeMigratedData,
        nodeKinds: queryParam.nodeKinds ?? [
          SystemNodeKindInput.FileNode,
          SystemNodeKindInput.FolderNode,
        ],
      }),
    keepPreviousData: options?.keepPreviousData,
    // staleTime: Infinity,
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retry: (failureCount, errorObject: any) => {
      const error = isArray(errorObject) ? errorObject[0] : errorObject;

      const isTimeoutError = error?.extensions?.exception?.code === MAX_TIME_MS_EXPIRED_ERROR_CODE;

      if (isTimeoutError || failureCount === DEFAULT_ERROR_RETRY_COUNT) {
        return false;
      }

      return true;
    },
  });

  const mappedResponse = useMemo(
    () => ({
      systemNodes: response.data?.listSystemNodesByParentNodeId?.data?.filter(isDefined) || [],
      totalNumberOfFiles: response.data?.listSystemNodesByParentNodeId?.totalNumberOfFiles || 0,
      totalNumberOfPages: response.data?.listSystemNodesByParentNodeId?.totalNumberOfPages || -1,
    }),
    [response.data?.listSystemNodesByParentNodeId],
  );

  return { response, ...mappedResponse };
};

useListSystemNodesByParentNodeIdQuery.getQueryKey = getQueryKey;
