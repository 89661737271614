import { gqlQueryFactory } from '@/graphql/gqlOperations';
import {
  DownloadContext,
  DownloadStore,
  StreamDownloaderFactory,
  StreamSaver,
} from '@skand/downloader';
import { toast } from '@skand/ui';
import { jotaiStore } from './jotaiStore';

const downloadContext = new DownloadContext({
  binarySaver: new StreamSaver(),
  downloaderFactory: new StreamDownloaderFactory(),
  gqlQueryFactory,
  jotaiStore,
  toastFn: toast,
});

export const downloadStore = new DownloadStore({
  downloadContext,
  maxTotalSize: Infinity,
});
