import { BranchTreeNode, FileTreeNode } from '../DownloadTreeNode';
import { EmptyDownloadTreeNodeError } from '../errors';
import { FileDownloadTask } from './FileDownloadTask';
import { IDownloadTask } from './IDownloadTask';
import { ZipDownloadTask } from './ZipDownloadTask';

export class DownloadTaskFactory {
  createFromTree(rootTreeNode: BranchTreeNode): IDownloadTask {
    const allNodes = rootTreeNode.map(node => node);

    if (allNodes.length === 0) {
      throw new EmptyDownloadTreeNodeError(rootTreeNode);
    } else if (allNodes.length === 1 && allNodes[0] instanceof FileTreeNode) {
      return new FileDownloadTask(allNodes[0]);
    } else {
      return new ZipDownloadTask(rootTreeNode);
    }
  }
}
