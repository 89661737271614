import logger from '@/utils/logger';
import { isAbortionError } from '@skand/downloader';
import { toast } from '@skand/ui';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

const displayError = (error: Error) => {
  if ('message' in error) return error.message;
  return error;
};

const displayErrors = (errors: unknown) => {
  if (errors instanceof Array) return displayError(errors[0]);
  else return errors;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },

  queryCache: new QueryCache({
    onError: error => {
      logger.error(error);
      if (isAbortionError(error)) return;
      toast({
        type: 'warn',
        message: `${displayErrors(error)}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  }),

  mutationCache: new MutationCache({
    onError: error => {
      logger.error(error);
      if (isAbortionError(error)) return;
      toast({
        type: 'warn',
        message: `${displayErrors(error)}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  }),
});
