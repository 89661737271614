import { NODES_TABLE_DEFAULT_PAGE_SIZE, NODES_TABLE_LARGE_PAGE_SIZE } from '@/constants/node';
import { ListSystemNodesByParentNodeIdQueryVariables } from '@/graphql/codegen/graphql';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useListSystemNodesByParentNodeIdQuery } from './useListSystemNodesByParentNodeIdQuery';
import { useSearchSystemNodesRecursiveQuery } from './useSearchSystemNodesRecursiveQuery';

export const useFetchSystemNodes = (
  queryParam: ListSystemNodesByParentNodeIdQueryVariables,
  options?: { keepPreviousData?: boolean; enableRecursiveSearch?: boolean },
) => {
  const hasSearchTerm = useMemo(
    () => !!queryParam.searchTerm && queryParam.searchTerm.length > 0,
    [queryParam],
  );

  const {
    totalNumberOfFiles: totalNumberOfFilesCheck,
    response: { isFetching: isCheckingTotalNumberOfFiles },
  } = useListSystemNodesByParentNodeIdQuery(
    {
      parentNodeId: queryParam.parentNodeId,
      pageSize: 1,
      pageIndex: 0,
      searchTerm: queryParam.searchTerm,
      nodeKinds: queryParam.nodeKinds,
      includeMigratedData: queryParam.includeMigratedData,
    },
    { keepPreviousData: false, enabled: !hasSearchTerm },
  );

  const { enableQuery, useMainQuery, useSearchQuery, updatedQueryParam } = useMemo(() => {
    const useMainQuery = !hasSearchTerm || !options?.enableRecursiveSearch;
    const useSearchQuery = !useMainQuery;

    const useLargePageSize =
      useSearchQuery ||
      (totalNumberOfFilesCheck <= NODES_TABLE_LARGE_PAGE_SIZE &&
        totalNumberOfFilesCheck > NODES_TABLE_DEFAULT_PAGE_SIZE);

    const pageSize = useLargePageSize ? NODES_TABLE_LARGE_PAGE_SIZE : NODES_TABLE_DEFAULT_PAGE_SIZE;

    const pageIndex = useLargePageSize ? 0 : queryParam.pageIndex;

    const params = {
      ...queryParam,
      pageSize,
      pageIndex,
    };

    return {
      enableQuery: !isCheckingTotalNumberOfFiles,
      useMainQuery,
      useSearchQuery,
      updatedQueryParam: params,
    };
  }, [
    hasSearchTerm,
    options?.enableRecursiveSearch,
    totalNumberOfFilesCheck,
    queryParam,
    isCheckingTotalNumberOfFiles,
  ]);

  const {
    systemNodes: systemNodesDataFromMainQuery,
    response: { isFetching: isMainQueryFetchingData, refetch: refetchSystemNodesFromMainQuery },
    ...paginationFromMainQuery
  } = useListSystemNodesByParentNodeIdQuery(updatedQueryParam, {
    ...options,
    enabled: enableQuery && useMainQuery,
  });

  const {
    systemNodes: systemNodesDataFromSearchQuery,
    response: { isFetching: isSearchQueryFetchingData },
    ...paginationFromSearchQuery
  } = useSearchSystemNodesRecursiveQuery(updatedQueryParam, {
    ...options,
    enabled: enableQuery && useSearchQuery,
  });

  const sortedSystemNodes = useMemo(() => {
    if (useSearchQuery) {
      return systemNodesDataFromSearchQuery;
    }

    const array = orderBy(systemNodesDataFromMainQuery, ({ name }) => name?.toLowerCase());

    array.sort((prevEl, nextEl) => {
      const prevElName = prevEl?.name ?? '';
      const nextElName = nextEl?.name ?? '';
      return prevElName.localeCompare(nextElName, undefined, { numeric: true });
    });
    const sortedNodes = orderBy(array, ['kind'], ['desc']);

    return sortedNodes;
  }, [systemNodesDataFromMainQuery, systemNodesDataFromSearchQuery, useSearchQuery]);

  const { totalNumberOfFiles, totalNumberOfPages } = useMemo(() => {
    const pagination = useMainQuery ? paginationFromMainQuery : paginationFromSearchQuery;
    return pagination;
  }, [paginationFromMainQuery, paginationFromSearchQuery, useMainQuery]);

  return {
    refetchSystemNodes: refetchSystemNodesFromMainQuery,
    systemNodes: sortedSystemNodes,
    totalNumberOfFiles,
    totalNumberOfPages,
    isFetchingSystemNodes:
      isCheckingTotalNumberOfFiles || isMainQueryFetchingData || isSearchQueryFetchingData,
  };
};
