import { jotaiStore } from '@/stores/jotaiStore';
import { atom, PrimitiveAtom } from 'jotai';
import { calcAverageSpeed } from './calcAverageSpeed';
import { IProgress } from './IProgress';

export class Progress implements IProgress {
  private _store = jotaiStore;

  readonly bytesLoadedAtom = atom(0);
  readonly bytesTotalAtom: PrimitiveAtom<number>;
  readonly itemsTotalAtom = atom(1);
  readonly loadFinishedAtAtom = atom<null | Date>(null);
  readonly loadStartedAtAtom = atom<null | Date>(null);

  readonly averageSpeedAtom = atom(get => {
    return calcAverageSpeed(
      get(this.bytesLoadedAtom),
      get(this.loadStartedAtAtom),
      get(this.loadFinishedAtAtom),
    );
  });
  readonly hasFinishedAtom = atom(get => get(this.loadFinishedAtAtom) !== null);
  readonly hasStartedAtom = atom(get => get(this.loadStartedAtAtom) !== null);
  readonly proportionAtom = atom(get => {
    return get(this.bytesTotalAtom) === 0
      ? 0
      : get(this.bytesLoadedAtom) / get(this.bytesTotalAtom);
  });

  readonly itemsLoadedAtom = atom(get => {
    const hasFinished = get(this.hasFinishedAtom);
    return hasFinished ? 1 : 0;
  });

  constructor(bytesTotal?: number) {
    this.bytesTotalAtom = atom(bytesTotal ?? 0);
  }

  update(bytesLoaded: number, bytesTotal?: number) {
    this._store.set(this.bytesLoadedAtom, bytesLoaded);
    this._store.set(this.bytesTotalAtom, prev => (bytesTotal === undefined ? prev : bytesTotal));
    this._store.set(this.loadStartedAtAtom, prev => prev ?? new Date());
  }

  finish() {
    this._store.set(this.loadFinishedAtAtom, new Date());
  }

  reset() {
    this._store.set(this.bytesLoadedAtom, 0);
    this._store.set(this.loadFinishedAtAtom, null);
    this._store.set(this.loadStartedAtAtom, null);
  }
}
