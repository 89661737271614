import { DownloadSystemNodesMutationVariables, File } from '@/graphql/codegen/graphql';
import { DOWNLOAD_SYSTEM_NODES } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useSystemNodesBySystemNodeIdsForPreview } from '@/hooks/useSystemNodesBySystemNodeIdsForPreview';
import { downloadFiles, setIsOpenDownloadDialog, setIsRequestingDownload } from '@/stores/download';
import { downloadStore } from '@/stores/downloadStore/downloadStore';
import { DATASETS_ADVANCED_DOWNLOADER, DATASETS_ZIP_DOWNLOADER } from '@/utils/split';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuItem } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { FoldersPageTableData } from './useFoldersPageColumns';

export interface DownloadMenuItemProps {
  fileId?: string;
  nodeId: string;
  selectedRows: FoldersPageTableData[];
  disabled?: boolean;
}

export const DownloadMenuItem = ({ fileId, nodeId, disabled = false }: DownloadMenuItemProps) => {
  const treatments = useTreatments([DATASETS_ADVANCED_DOWNLOADER, DATASETS_ZIP_DOWNLOADER]);
  const isAdvancedDownloaderEnabled = treatments[DATASETS_ADVANCED_DOWNLOADER].treatment === 'on';
  const isZipDownloaderEnabled = treatments[DATASETS_ZIP_DOWNLOADER].treatment === 'on';

  const { findSystemNodeById } = useSystemNodesBySystemNodeIdsForPreview();
  const systemNode = findSystemNodeById(nodeId);

  const downloadSystemNodes = useMutation({
    mutationFn: (variables: DownloadSystemNodesMutationVariables) => {
      return request(DOWNLOAD_SYSTEM_NODES, variables);
    },
  });

  const handleDownloadFile = useCallback(async () => {
    if (fileId && systemNode?.file?.signedGetObjectDownloadUrl) {
      downloadFiles([systemNode.file] as File[], isAdvancedDownloaderEnabled);
    } else {
      downloadSystemNodes.mutate({ nodeIds: [nodeId] });
      setIsRequestingDownload(true);
    }

    setIsOpenDownloadDialog(true);
  }, [fileId, systemNode?.file, isAdvancedDownloaderEnabled, downloadSystemNodes, nodeId]);

  const downloadV2 = () => {
    downloadStore.createTaskManagerAndStart([nodeId]);
  };

  return (
    <DropdownMenu.Item asChild className="cursor-pointer outline-none">
      <MenuItem
        disabled={disabled}
        onClick={() => (isZipDownloaderEnabled ? downloadV2() : handleDownloadFile())}
      >
        Download
      </MenuItem>
    </DropdownMenu.Item>
  );
};
