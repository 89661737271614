import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { Kind } from 'graphql';
import { GqlSyntaxError } from './errors';

export const getOperationName = <Vars, Data>(document: TypedDocumentNode<Data, Vars>) => {
  if (document.kind !== Kind.DOCUMENT) throw new GqlSyntaxError();
  if (document.definitions.length !== 1) throw new GqlSyntaxError();
  const definition = document.definitions[0];
  if (definition.kind !== Kind.OPERATION_DEFINITION) throw new GqlSyntaxError();
  if (!definition.name) throw new GqlSyntaxError();
  return definition.name.value;
};
