import { atom } from 'jotai';
import { FileTreeNode } from '../DownloadTreeNode';
import { IProgress } from '../Progress/IProgress';
import { IDownloadTask } from './IDownloadTask';
import { saveBlob } from './saveBlob';

export class FileDownloadTask implements IDownloadTask {
  readonly progress: IProgress;
  readonly treeNode: FileTreeNode;
  readonly name: string;

  readonly errorsAtom = atom<unknown[]>(get => {
    const error = get(this.treeNode.downloader.errorAtom);
    return error === null ? [] : [error];
  });

  constructor(treeNode: FileTreeNode) {
    this.name = treeNode.nodeName;
    this.progress = treeNode.downloader.progress;
    this.treeNode = treeNode;
  }

  async start(signal: AbortSignal) {
    const blob = await this.treeNode.downloader.start(signal);
    saveBlob(blob, this.name);
  }
}
