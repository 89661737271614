import { GqlQuery } from '@/graphql/operations/GqlQuery';
import { gql } from '../codegen';

export const document = gql(`
  query ListSystemNodesBySystemNodeIdsForPreview($systemNodeIds: [ID]!) {
    findSystemNodesByIds(systemNodeIds: $systemNodeIds, hydrateFiles: true) {
      ... on FileNode {
        __typename
        id
        kind
        name
        parentNodeId
        fileId

        file {
          ... on AssetFile {
            id
            signedGetObjectUrl
            signedGetObjectDownloadUrl
            storage { name, size }
          }
          ... on ImageFile {
            id
            signedGetObjectUrl
            signedGetObjectDownloadUrl
            storage { name, size }
          }
          ... on ObjFile {
            id
            signedGetObjectUrl
            signedGetObjectDownloadUrl
            storage { name, size }
          }
          ... on Ortho2dFile {
            id
            signedGetObjectUrl
            signedGetObjectDownloadUrl
            storage { name, size }
          }
          ... on Tileset3dFile {
            id
            signedGetObjectUrl
            signedGetObjectDownloadUrl
            storage { name, size }
          }
        }
      }
    }
  }
`);

export const ListSystemNodesBySystemNodeIdsForPreview = GqlQuery.fromGql(document);
