import { queryClient } from '@/graphql/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GqlOperation, IGqlOperation } from './GqlOperation';

export class GqlQuery<Vars extends Record<string, unknown>, Data extends Record<string, unknown>> {
  private _operation: IGqlOperation<Vars, Data>;
  private _queryClient: QueryClient;

  constructor(operation: IGqlOperation<Vars, Data>, queryClient: QueryClient) {
    this._operation = operation;
    this._queryClient = queryClient;
  }

  get document() {
    return this._operation.document;
  }

  get operationName() {
    return this._operation.operationName;
  }

  static fromGql<V extends Record<string, unknown>, D extends Record<string, unknown>>(
    document: TypedDocumentNode<D, V>,
  ) {
    const operation = GqlOperation.fromGql(document);
    return new GqlQuery(operation, queryClient);
  }

  async request(
    variables: Vars,
    options?: {
      signal?: AbortSignal;
    },
  ) {
    return this._queryClient.fetchQuery({
      queryFn: () => this._operation.request(variables, options),
      queryKey: this.getQueryKey(variables),
    });
  }

  useQuery(variables: Vars, options?: UseQueryOptions<Data>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const query = useQuery<Data>(
      this.getQueryKey(variables),
      ({ signal }) => this._operation.request(variables, { signal }),
      options,
    );
    return query;
  }

  getQueryKey(variables: Partial<Vars>) {
    return [this._operation.operationName, variables];
  }

  invalidate(variables: Partial<Vars>) {
    queryClient.invalidateQueries(this.getQueryKey(variables));
  }
}
