import { GqlQuery } from '@/graphql/operations/GqlQuery';
import { gql } from '../codegen';

export const document = gql(`
  query ListSystemNodesByParentNodeIdForDownload(
    $nodeKinds: [SystemNodeKindInput!]!
    $pageIndex: Int!
    $pageSize: Int!
    $parentNodeId: ID
  ) {
    listSystemNodesByParentNodeId(
      hydrateFiles: false
      includeMigratedData: true
      nodeKinds: $nodeKinds
      pageIndex: $pageIndex
      pageSize: $pageSize
      parentNodeId: $parentNodeId
      searchTerm: null
    ) {
      data {
        ... on FileNode {
          __typename
          id
          kind
          name
          parentNodeId
          fileId
        }
        ... on FolderNode {
          __typename
          id
          kind
          name
          parentNodeId
        }
        ... on LinkNode {
          __typename
          id
          kind
          name
          parentNodeId
          sourceNodeId
        }
      }
      totalNumberOfPages
      totalNumberOfFiles
    }
  }
`);

export const ListSystemNodesByParentNodeIdForDownload = GqlQuery.fromGql(document);
