import { gql } from '@/graphql/codegen';
import { GqlQuery } from '@/graphql/operations/GqlQuery';

const document = gql(`
  query ListFilesByFileIdsForDownload($fileIds: [ID!]!) {
    filesByIds(fileIds: $fileIds) {
      ... on AssetFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on ImageFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on ObjFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on Ortho2dFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
      ... on Tileset3dFile {
        id
        signedGetObjectDownloadUrl
        storage { name, size }
      }
    }
  }
`);

export const ListFilesByFileIdsForDownload = GqlQuery.fromGql(document);
